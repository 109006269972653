import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './page.css'

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    componentDidMount () {
        let that = this;
        this.timeoutId = setTimeout(() => {
            that.setState({
                loading: false,
            })
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render () {
        var content = this.props.content ?
            (<div dangerouslySetInnerHTML={{ __html: this.props.content }} />) :
            (<div>{this.props.rawContent}</div>)

        return (
            <div id="main">
                <article className={this.state.loading ? '' : 'active'}>
                    <h2 className="major">{this.props.title}</h2>
                    <div>{content}</div>
                    <Link to="/">
                        <div className="close">Close</div>
                    </Link>
                </article>
            </div>
        )
    }
}

Page.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    rawContent: PropTypes.node,
}

export default Page

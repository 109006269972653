import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/layout'
import Page from "../components/page";

export default ({ data }) => {
  const content = data.markdownRemark
  return (
    <Layout blurBackground={true}>
      <Page
        title={content.frontmatter.title}
        content={content.html}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
